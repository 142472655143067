.container {
  .sales_title {
    padding: 10px 0px 10px 10px;
    width: 100%;
    position: fixed;
    background: #ffffff;
    text-align: left;
    top: 0;
    font-weight: 500;
    border-bottom: 2px solid #dee6f0;
    z-index: 1;
    font-weight: bold;
    font-size: 20px;
  }
  .board-list {
    display: flex;
    padding-top: 48px;
    justify-content: space-evenly;
    background-color: #eceff5;
    overflow: auto;
    max-height: 100vh;
    z-index: 0;
  }
  .table-list {
    margin-top: 50px;
  }
  .table_title {
    display: flex;
    align-items: center;
    input {
      width: 30%;
      border-radius: 5px;
      height: 40px;
      margin-left: 30px;
    }
  }
  .status-board {
    width: 25%;
    position: relative;
    height: 100vh;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 10px;
    border-left: 2px solid #dee6f0;
    .notification_text {
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 5px;
      margin-right: 10px;
    }
  }
  .ant-card-bordered {
    cursor: pointer;
    border-radius: 10px;
    margin: 10px 0px;
    .description_text {
      opacity: 0.6;
      font-size: 11px;
    }
    .order_card--text_group {
      display: flex;
      justify-content: space-between;
      opacity: 0.8;
      p {
        width: 80%;
      }
    }
    p {
      margin: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 5px;
    }
  }
}
.pl-5 {
  padding-left: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}
.main {
  display: flex;
  width: 100%;
  .menu-container {
    padding: 20px;
    background-color: #1b2534;
    .collaspe-title {
      display: flex;
      justify-content: space-between;
      h2 {
        color: white;
        margin: 0;
      }
      button {
        border-color: #1b2534;
        background: #1b2534;
      }
    }
  }
  .menu {
    width: max-content;
    position: fixed;
    background-color: #1f2c38;
    min-height: 100vh;
    max-height: 100%;
    .infor-user {
      text-align: center;
      padding: 25px 0;
      h2 {
        color: white;
        margin: 0;
      }
      h3 {
        color: #747474;
        font-size: 12px;
      }
    }
    .ant-menu-dark {
      background-color: #1b2534;
    }
    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
      background-color: #ffffff1a !important;
      border-radius: 10px;
    }
  }
  .main-body {
    width: 100%;
    .table-saleorder {
      height: 300px;
      overflow: auto;
    }
  }
}

.login {
  margin: 10rem auto;
  height: 100vh;
  .ant-form {
    position: relative;
    top: 30%;
    .ant-form-item {
      flex-direction: column;
      .ant-form-item-label {
        text-align: left;
      }
      label {
        font-size: 16px;
        font-weight: 600;
        font-family: math;
      }
      .ant-input-affix-wrapper,
      input {
        padding: 10px;
        outline: none;
        border-radius: 5px;
      }
      button {
        outline: none;
        border: none;
        border-radius: 7px;
        color: white;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        height: max-content;
        width: 102px;
      }
    }
  }
}
.ant-modal-mask {
  background-color: #00000000 !important;
}
// Responsive menu
.menu-respon {
  display: none;
}
.tablist-respon {
  display: none;
}
@media only screen and (max-width: 600px) {
  .tablist-desktop {
    display: none;
  }
  .tablist-respon {
    display: block;
    .ant-list-item {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      width: 100%;
    }
    .ant-list-item-meta {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 900px) {
  .container {
    .sales_title {
      position: relative;
      input {
        width: 70%;
      }
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    .menu {
      position: relative;
      min-height: 0;
      background-color: #fff;
      height: max-content;
      width: 100%;
    }
    .main-body {
      margin-left: 0px !important;
      padding: 20px;
      .ant-page-header-heading-left {
        flex-direction: column;
        margin: 0 auto;
      }
    }
  }
  .menu-container {
    display: none;
  }
  .menu-respon {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    padding-bottom: 0;
    text-align: right;
    .btn-menu-respon {
      background: #001529;
      border-color: #001529;
    }
  }
  .ant-drawer-body {
    background-color: #001529;
    .infor-user {
      text-align: center;
      padding: 25px 0;
      h2 {
        color: white;
        margin: 0;
      }
      h3 {
        color: #747474;
        font-size: 12px;
      }
    }
    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
      background-color: #ffffff1a !important;
      border-radius: 10px;
    }
  }
  .ant-drawer-header {
    background-color: #001529 !important;
    .collaspe-title {
      color: #fff;
    }
    h2 {
      color: #fff;
      margin-top: revert;
    }
  }
}

.logout-title {
  position: fixed;
  bottom: 0;
  color: #ffffff;
}

//sale order
.sale-order_form {
  .form-btn_sale {
    .ant-form-item-control-input {
      margin: auto;
    }
  }
}
